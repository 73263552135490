import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { CoinContext } from "../../../context/CoinContext";
import LoadingTableSkeleton from "../../Elements/TableLoader";
import SynchronizedAreaChart2 from "../../Elements/Chart2";

interface WorkerData {
    workerId: string;
    hashRate15: string;
    hashRate24: string;
    rejected: string;
    status: string;
}

interface WorkerCounts {
    all: number;
    online: number;
    offline: number;
    dead: number;
}

interface WorkerChartResponse {
    data: any;

}
interface DataItem {
    time: string;
    hashrate: number;
}

interface ApiResponseItem {
    timeStamp: number;
    hash: number;
}

const Workers: React.FC = () => {
    const [allWorkersData, setAllWorkersData] = useState<WorkerData[]>([]);
    const [filter, setFilter] = useState('all');
    const [workersData, setWorkersData] = useState<WorkerData[]>([]);
    const [counts, setCounts] = useState<WorkerCounts>({ all: 0, online: 0, offline: 0, dead: 0 });
    const [total, setTotal] = useState({ hashRate15: 0, hashRate24: 0, rejected: 0 });
    const [openRowIndex, setOpenRowIndex] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false)
    const [chartData, setChartData] = useState<DataItem[]>([]);

    const coinContext = useContext(CoinContext);


    const toggleRow = (index: number, workerId: string) => {
        if (openRowIndex === index) {
            setOpenRowIndex(null);
        } else {
            setOpenRowIndex(index);
            handleWorkerChart(workerId);
        }
    };


    const cleanDigit = (n: any): string => {
        return n == null ? "0.00" : Number(n).toFixed(2);
    };

    const getWorkerData = (callback: (data: WorkerData[] | null) => void, coin: string) => {
        setLoading(true)
        axios.get(`/pool/get-workers?hours=1&coin=${coin}`)
            .then(response => {
                callback(formatWorkerData(response.data?.data));
                setLoading(false)
            })
            .catch(e => {
                console.error(e);
                callback(null);
                setLoading(false)
            });
    };

    const handleWorkerChart = (workerId: string | number) => {
        if (coinContext?.selectedCoin) {
            const coin = coinContext?.selectedCoin;
            axios.get(`/pool/worker/worker-chart?coin=${coin}&worker=${workerId}`)
                .then(response => {
                    const formattedData: DataItem[] = response.data.map((n: ApiResponseItem) => ({
                        time: formatTimeLabel(n.timeStamp),
                        hashrate: n.hash,
                    }));

                    setChartData(formattedData);
                })
                .catch(error => {
                    console.error('Error fetching chart data:', error);
                    setChartData([]);
                });
        }
    };

    const formatTimeLabel = (timestamp: number): string => {
        return new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const formatWorkerData = (data: any): WorkerData[] | null => {
        if (!data) return null;
        return data.map((o: any) => ({
            ...o,
            hashRate15: (o.hashRate15 / 1).toString(),
            hashRate24: (o.hashRate24 / 1).toString()
        }));
    };

    useEffect(() => {
        if (coinContext) {
            const coin = coinContext.selectedCoin;
            getWorkerData(data => {
                setAllWorkersData(data ?? []);
            }, coin);
        }
    }, [coinContext]);


    useEffect(() => {
        doFilter(filter);
        doCount();
    }, [allWorkersData, filter]);

    const doCount = () => {
        const newCount = { all: 0, online: 0, offline: 0, dead: 0 };
        allWorkersData.forEach(n => {
            switch (n.status.toLowerCase()) {
                case 'dead':
                    newCount.dead++;
                    break;
                case 'online':
                    newCount.online++;
                    newCount.all++;
                    break;
                case 'offline':
                    newCount.offline++;
                    newCount.all++;
                    break;
            }
        });
        setCounts(newCount);
    };

    const doFilter = (flag: string) => {
        setFilter(flag);
        const filteredWorkers = allWorkersData.filter(n =>
            flag === 'all' ? n.status.toLowerCase() !== 'dead' : n.status.toLowerCase() === flag
        );
        setWorkersData(filteredWorkers);
        const newTotal = { hashRate15: 0, hashRate24: 0, rejected: 0 };
        filteredWorkers.forEach(n => {
            newTotal.hashRate15 += Number(n.hashRate15);
            newTotal.hashRate24 += Number(n.hashRate24);
            newTotal.rejected += Number(n.rejected);
        });
        newTotal.rejected = filteredWorkers.length ? newTotal.rejected / filteredWorkers.length : 0;
        setTotal(newTotal);
    };

    return (
        <div className="flex flex-col">
            <h3 className="font-semibold hidden md:block text-3xl text-gray-500 pt-4 pb-2">Workers</h3>
            <div className="flex space-x-2 p-2">
                {(['all', 'online', 'offline', 'dead'] as const).map(status => (
                    <button
                        key={status}
                        className={`w-32 text-xs md:text-sm py-2 ${filter === status ? 'bg-[#F2A50F] text-white' : 'bg-white hover:bg-yellow-100 border text-gray-700'} rounded flex items-center justify-center`}
                        disabled={filter === status}
                        onClick={() => doFilter(status)}
                    >
                        {(status === 'online' || status === 'offline') && (
                            <span
                                className={`w-2 h-2 rounded-full mr-2 ${status === 'online' ? 'bg-green-500' : 'bg-red-500'}`}
                            />
                        )}
                        {status === 'dead' && (
                            <span className="w-2 h-2 rounded-full mr-2 bg-gray-500" />
                        )}
                        {status.charAt(0).toUpperCase() + status.slice(1)} {counts[status]}
                    </button>
                ))}

            </div>
            {loading ?
                <LoadingTableSkeleton />
                :
                <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                        <thead className="text-gray-700 uppercase bg-[#D5EEFF] borde">
                            <tr>
                                <th scope="col" className="px-1 py-2 md:px-6 md:py-3 text-xs">Worker</th>
                                <th scope="col" className="px-1 py-2 md:px-6 md:py-3 text-xs text-center">15m Hashrate</th>
                                <th scope="col" className="px-1 py-2 md:px-6 md:py-3 text-xs text-center">24hr Hashrate</th>
                                <th scope="col" className="px-1 py-2 md:px-6 md:py-3 text-xs text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {workersData.length === 0 ? (
                                <tr>
                                    <td colSpan={7} className="text-center text-lg p-4">No data available</td>
                                </tr>
                            ) : (
                                workersData.map((worker, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="bg-white border m-1 mb-4 cursor-pointer" onClick={() => toggleRow(index, worker?.workerId)}>
                                            <td className="px-1 py-2 md:px-6 md:py-3 text-center text-xs md:text-sm">
                                                <div className="flex items-center content-center font-medium text-gray-900 whitespace-nowrap">
                                                    <div
                                                        className={`w-2 h-2 rounded-full mr-2 ${worker?.status === 'ONLINE' ? 'bg-green-500' :
                                                            worker?.status === 'OFFLINE' ? 'bg-red-500' :
                                                                worker?.status === 'DEAD' ? 'bg-gray-500' :
                                                                    'bg-gray-300'
                                                            }`}
                                                    ></div>
                                                    <span>{worker?.workerId}</span>
                                                </div>
                                            </td>
                                            <td className="px-1 py-2 md:px-6 md:py-3 text-center text-xs md:text-sm">{cleanDigit(worker?.hashRate15)} TH/s</td>
                                            <td className="px-1 py-2 md:px-6 md:py-3 text-center text-xs md:text-sm">{cleanDigit(worker?.hashRate24)} TH/s</td>
                                            <td className="px-1 py-2 md:px-6 md:py-3 text-right">
                                                <span className={`inline-block text-gray-500  md:bg-[#D5EEFF] py-1 px-2 md:py-1 md:px-4 rounded-full `}>
                                                    <FontAwesomeIcon icon={faChevronDown} className={`transform transition-transform duration-300 ${openRowIndex === index ? 'rotate-180' : ''}`} />
                                                </span>
                                            </td>
                                        </tr>
                                        {openRowIndex === index && (
                                            <tr className="bg-white border">
                                                <td colSpan={7} className="py-1">
                                                    <p className='text-sm md:text-2xl font-medium text-[#1B4788] ml-6 mb-2 pt-2'>Hashrate of last 24 hours</p>
                                                    <p className='text-xs md:text-md font-medium text-gray-400 ml-6 mb-2 pb-2'>Hashrate (TH/s)</p>
                                                    <SynchronizedAreaChart2 data={chartData} />
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))
                            )}
                        </tbody>

                    </table>
                </div>

            }
        </div>
    );
};

export default Workers;

import React from 'react';

const LoadingTableSkeleton: React.FC = () => {
    return (
        <div role="status" className="animate-pulse w-full my-2">
            <div className="h-10 mb-1 bg-gray-200"></div>
            <div className="h-10 mb-1 bg-gray-200"></div>
            <div className="h-10 mb-1 bg-gray-200"></div>
            <div className="h-10 mb-1 bg-gray-200"></div>
            <div className="h-10 mb-1 bg-gray-200"></div>
            <div className="h-10 mb-1 bg-gray-200"></div>
        </div>
    );
};

export default LoadingTableSkeleton;



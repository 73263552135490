import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './Auth';
import axios from 'axios';
import GradientButton from '../Elements/GradientButton';

const SignInScreen: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [logingIn, setLogingIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [openResetModal, setOpenResetModal] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const [resetUsername, setResetUsername] = useState('');

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        setLogingIn(true);

        signInWithEmailAndPassword(auth, username, password)
            .then((userCredential) => {
                navigate('/');
                setIsLoading(false);
                setLogingIn(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setLogingIn(false);
                setErrorMessage('Invalid credentials...');
            });
    };

    const handlePasswordVisibilityToggle = () => {
        setShowPassword(!showPassword);
    };

    const handleForgotPasswordClick = () => {
        setOpenResetModal(true);
    };

    const handleResetPassword = () => {
        axios.post('/pool/auth/resetpassword', {
            email: resetEmail,
            username: resetUsername
        })
            .then(response => {
                const { status, message } = response.data;
                if (status) {
                    sendPasswordResetEmail(auth, resetEmail)
                        .then(() => {
                            setSuccessMessage("Password reset email sent.");
                            setOpenResetModal(false);
                            setResetEmail('');
                            setResetUsername('');
                        })
                        .catch(() => {
                            setErrorMessage("Failed to send password reset email.");
                        });
                } else {
                    setErrorMessage(message);
                }
            })
            .catch(() => {
                setErrorMessage('Failed to send reset email.');
            });
    };

    const handleCloseSnackbar = () => {
        setErrorMessage(null);
        setSuccessMessage(null);
    };


    return (
        <div
            className="flex items-center justify-center min-h-screen"
            style={{
                backgroundImage: `url('/assets/user_background.jpeg')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <div className="absolute inset-0 bg-[#04426E] opacity-60"></div>
            <div className="bg-white bg-opacity-20 backdrop-blur-md p-8 m-4 rounded-lg shadow-lg w-full max-w-md md:max-w-lg lg:max-w-md">
                {!openResetModal && <>
                    <h2 className="text-3xl font-bold text-center text-white mb-6">SIGN IN</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-white mb-1 font-medium" htmlFor="email">Email *</label>
                            <input
                                className="w-full p-2 rounded bg-white bg-opacity-90 text-gray-700 focus:outline-none font-medium"
                                type="email"
                                id="email"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                                placeholder="Enter Email"
                            />
                        </div>
                        <div className="mb-4 relative">
                            <label className="block text-white mb-1 font-medium" htmlFor="password">Password *</label>
                            <div className="relative">
                                <input
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    id="hs-toggle-password" type={showPassword ? 'text' : 'password'}
                                    className="w-full p-2 rounded bg-white bg-opacity-90 text-gray-700 focus:outline-none font-medium"
                                    placeholder="Enter password"
                                />
                                <button type="button" data-hs-toggle-password='{"target": "#hs-toggle-password"}' className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500" onClick={() => setShowPassword(!showPassword)}>
                                    <svg className="shrink-0 size-3.5" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path className="hs-password-active:hidden" d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                        <path className="hs-password-active:hidden" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                                        <path className="hs-password-active:hidden" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                                        <line className="hs-password-active:hidden" x1="2" x2="22" y1="2" y2="22"></line>
                                        <path className="hidden hs-password-active:block" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                                        <circle className="hidden hs-password-active:block" cx="12" cy="12" r="3"></circle>
                                    </svg>
                                </button>
                            </div>
                            <i className="fas fa-eye absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                        </div>
                        <div className="flex items-center justify-between mb-6 text-sm">
                            <label className="flex items-center text-white cursor-pointer">
                                <input type="checkbox" className="mr-2" />
                                Remember me.
                            </label>
                            <a href="#" className="text-white" onClick={handleForgotPasswordClick}>Forget Password</a>

                        </div>
                        <GradientButton isLoading={isLoading}>
                            SIGN IN
                        </GradientButton>

                    </form>
                    <div className="text-center mt-6">
                        <p className="text-white text-sm">
                            Don't have an account ?
                            <span
                                className="text-white font-bold cursor-pointer"
                                onClick={() => navigate('/signup')}
                            >
                                Sign up →
                            </span>
                        </p>
                    </div>


                </>}
                {openResetModal && (
                    <>
                        <h2 className="text-xl font-bold text-center text-white mb-6">Reset Password</h2>
                        <div className="mb-4">
                            <label className="block text-white mb-1 font-medium" htmlFor="resetUsername">Username</label>
                            <input
                                type="text"
                                id="resetUsername"
                                value={resetUsername}
                                onChange={(e) => setResetUsername(e.target.value)}
                                className="w-full p-2 rounded bg-white bg-opacity-90 text-gray-700 focus:outline-none font-medium"
                                placeholder="Enter your username"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-white mb-1 font-medium" htmlFor="resetEmail">Email</label>
                            <input
                                type="email"
                                id="resetEmail"
                                value={resetEmail}
                                onChange={(e) => setResetEmail(e.target.value)}
                                className="w-full p-2 rounded bg-white bg-opacity-90 text-gray-700 focus:outline-none font-medium"
                                placeholder="Enter your email"
                            />
                        </div>
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={() => setOpenResetModal(false)}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleResetPassword}
                                className="p-3 rounded-lg font-bold hover:opacity-90 transition duration-300"
                                style={{
                                    background: 'linear-gradient(98.9deg, #FCBE13 -5.32%, #EB910D 74.21%)',
                                    backgroundClip: 'padding-box',
                                    border: 'none',
                                    color: 'white'
                                }}
                            >
                                Reset Password
                            </button>
                        </div>
                    </>
                )}
            </div>
            {(errorMessage || successMessage) && (
                <div
                    className={`flex-1 text-sm font-medium ${successMessage ? 'bg-green-100 border border-green-400 text-green-700' : 'bg-red-100 border border-red-400 text-red-700'} px-4 py-3 fixed bottom-5  left-10  p-4 rounded-md shadow-lg w-full max-w-sm flex items-center`}
                    role="alert"
                >
                    <div
                        className={`flex-1 text-sm font-medium ${successMessage ? 'text-green-700' : 'text-red-700'
                            }`}
                    >
                        {successMessage || errorMessage}
                    </div>
                    <button
                        className="ml-4 text-gray-500 hover:text-gray-700"
                        onClick={handleCloseSnackbar}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-5 h-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
            )}
        </div>
    );
};

export default SignInScreen;

import React from 'react';

interface SnackbarProps {
    error?: string;
    successMessage?: string;
    onClose: () => void;
}

const Snackbar: React.FC<SnackbarProps> = ({ error, successMessage, onClose }) => {
    if (!error && !successMessage) return null;

    return (
        <div
            className={`flex-1 text-sm font-medium ${successMessage ? 'bg-green-100 border border-green-400 text-green-700' : 'bg-red-100 border border-red-400 text-red-700'} fixed bottom-5 left-10 p-4 rounded-md shadow-lg w-full max-w-sm flex items-center`}
            role="alert"
        >
            <div className={`flex-1 text-sm font-medium ${successMessage ? 'text-green-700' : 'text-red-700'}`}>
                {successMessage || error}
            </div>
            <button
                className="ml-4 text-gray-500 hover:text-gray-700"
                onClick={onClose}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-5 h-5"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </button>
        </div>
    );
};

export default Snackbar;

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import AOS from 'aos';
import 'aos/dist/aos.css';
import WorkerData from './Components/machinedata/MachineHandle';
import SignInScreen from './Components/auth/SignInScreen';
import SignUpScreen from './Components/auth/SignUpScreen';
import { onAuthStateChanged, onIdTokenChanged, User } from 'firebase/auth';
import { auth, FBuser, signedUser } from './Components/auth/Auth';
import axios from 'axios';
import { CoinProvider } from './context/CoinContext';
import { LogoLoader } from './Components/Elements/LogoLoader';

export const appContext = React.createContext<any | null>(null);

function App() {
  const [authStatus, setAuthStatus] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);
  const [authUpdate, setAuthUpdate] = useState<any>(null);


  const [authUserName, setAuthUserName] = useState<string>("");
  const [authUserEmail, setAuthUserEmail] = useState<string>("");
  const [authList, setAuthList] = useState<any[]>([]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, validateAuthToken);
    onIdTokenChanged(auth, validateAuthToken);
  }, []);

  function validateAuthToken(user: User | null) {
    if (user) {
      signedUser(processFBuser);
    } else {
      processFBuser(null);
    }
  }

  function processFBuser(user: FBuser | null) {
    if (user == null) {
      axios.defaults.headers.common['userId'] = '';
      axios.defaults.headers.common['userFbId'] = '';
      axios.defaults.headers.common['token'] = '';
      setAuthUserName("");
      setAuthStatus(false);
    } else {
      axios.defaults.headers.common['userId'] = user.email;
      axios.defaults.headers.common['userFbId'] = user.id;
      axios.defaults.headers.common['token'] = user.token;
      setAuthStatus(true);
      setAuthUpdate(new Date().getTime());
    }
    setLoading(false);
    axios.get(`/pool/info`)
      .then(response => {
        setAuthUserName(response.data.userName);
        setAuthUserEmail(response.data.email);
      }).catch(() => { });
  }

  useEffect(() => {
    axios.get(`/pool/process_feed`)
      .then(response => {
        setAuthList(response.data);
      }).catch(() => { });
  }, [authUpdate])


  useEffect(() => {
    axios.defaults.headers.common['userId'] = authUserEmail
  }, [authUserEmail])

  return (<appContext.Provider value={{ authUserName, setAuthUserName, authUserEmail, setAuthUserEmail, authList }}>
    <CoinProvider>
      <BrowserRouter>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <LogoLoader />
          </div>
        ) : (
          <Routes>
            {authStatus === null && (
              <>
                <Route path="/" element={<Home />} />
              </>
            )}
            {authStatus === false && (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<SignInScreen />} />
                <Route path="/signup" element={<SignUpScreen />} />
              </>
            )}
            {authStatus === true && (
              <>
                <Route path="/" element={<WorkerData />} />
                <Route path="/login" element={<Navigate to="/" />} />
                <Route path="/signup" element={<Navigate to="/" />} />
              </>
            )}
          </Routes>
        )}
      </BrowserRouter>
    </CoinProvider>
  </appContext.Provider>
  );
}

export default App;

import { useContext, useEffect, useState } from "react";
import axios from "axios";
import RevenueReward from "./Reward";
import RevenuePayouts from "./Payouts";
import { CoinContext } from "../../../context/CoinContext";
import LoadingTableSkeleton from "../../Elements/TableLoader";



function RevenuePage() {
    const [tabSelected, setTabSelected] = useState("Rewards");
    const [data, setData] = useState({ totalBalance: 0, coin: "", rewards: [], payouts: [] });
    const [isLoading, setIsLoading] = useState(true);

    const coinContext = useContext(CoinContext);

    useEffect(() => {
        if (coinContext) {
            const coin = coinContext.selectedCoin;
            const fetchData = async () => {
                setIsLoading(true);
                try {
                    const response = await axios.get(`/pool/reward?coin=${coin}`);
                    setData(response.data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchData();
        }
    }, [coinContext]);


    const handleChange = (newValue: any) => {
        setTabSelected(newValue);
    };

    const showLoader = isLoading && (!data.rewards.length && !data.payouts.length);

    return (
        <div className="flex flex-col">
            <h3 className="font-semibold hidden md:block text-3xl text-gray-500 pt-4 pb-2">Revenue</h3>
            <nav className="flex w-11/12 md:w-full bg-[#2286CC] p-1 md:p-2 rounded-lg items-center mx-auto">
                <button
                    key={"Rewards"}
                    className={`py-2 px-4 text-sm md:text-base focus:outline-none ${tabSelected === "Rewards" ? "font-medium text-white" : "text-gray-300 hover:text-white"}`}
                    onClick={() => handleChange("Rewards")}
                >
                    Rewards
                </button>
                <span className="text-white text-3xl"> | </span>
                <button
                    key={"Payouts"}
                    className={`py-2 px-4 text-sm md:text-base focus:outline-none ${tabSelected === "Payouts" ? "font-medium text-white" : "text-gray-300 hover:text-white"}`}
                    onClick={() => handleChange("Payouts")}
                >
                    Payouts
                </button>
            </nav>
            <div>
                {isLoading ?
                    <LoadingTableSkeleton /> :
                    <>
                        {tabSelected === "Rewards" && <RevenueReward rewards={data.rewards} coin={data.coin} />}
                        {tabSelected === "Payouts" && <RevenuePayouts payouts={data.payouts} coin={data.coin} />}
                    </>
                }
            </div>
        </div>
    );
}

export default RevenuePage;

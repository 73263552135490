export const WhyChooseUsComponent = () => {

    return (
        <div className="text-center mb-12 flex flex-wrap justify-center bg-white py-16 bg-opacity-10">
            <h1 className="text-2xl md:text-4xl font-bold mb-2 md:mb-0">
                WHY CHOOSE US
            </h1>
            <div className="container flex flex-wrap justify-center">
                <div className="text-center w-full sm:w-1/2 md:w-1/3 xl:w-1/3 p-4">
                    <div className="inline-block mb-4">
                        <img
                            alt="coin"
                            className="w-2/3 mx-auto"
                            src="/assets/ic_coin3.svg"
                        />
                    </div>
                    <h2 className="text-lg font-bold">
                        CUSTOME POOL FOR IN-HOUSE CLIENTS
                    </h2>
                    <p className="mt-2">
                        We have technicians who are being trained by Bitmain. You can always reach out to us for assistance.
                    </p>
                </div>
                <div className="text-center w-full sm:w-1/2 md:w-1/3 xl:w-1/3 p-4">
                    <div className="inline-block mb-4">
                        <img
                            alt="coin"
                            className="w-2/3 mx-auto"
                            src="/assets/ic_coin1.svg"
                        />
                    </div>
                    <h2 className="text-lg font-bold">
                        FRACTIONAL PURCHASE
                    </h2>
                    <p className="mt-2">
                        Need to try before purchasing? Low on budget? Or diversify your risk across machine? You can now buy fractional hash rates instead of the whole machine.
                    </p>
                </div>
                <div className="text-center w-full sm:w-1/2 md:w-1/3 xl:w-1/3 p-4">
                    <div className="inline-block mb-4">
                        <img
                            alt="coin"
                            className="w-2/3 mx-auto"
                            src="/assets/ic_coin2.svg"
                        />
                    </div>
                    <h2 className="text-lg font-bold">
                        EASY HOSTING PLANS
                    </h2>
                    <p className="mt-2">
                        We charge an all-inclusive hosting rate of 0.30AED/KW. This covers electricity, routine maintenance and monitoring support.
                    </p>
                </div>
            </div>
        </div>
    );
};
import { useEffect, useRef, useState } from "react";
import { HomeComponent } from "../Components/Homescreen/Home";
import { About } from "../Components/Homescreen/About";
import { Contact } from "../Components/Homescreen/Contact";
import { Footer } from "../Components/Homescreen/Footer";
import SvgDecorator from "../Components/Homescreen/SvgDecor";
import { WhyChooseUsComponent } from "../Components/Homescreen/WhyChooseUs";
import { JoinSegmentCloudComponent } from "../Components/Homescreen/JoinSegment";
import Navbar from "../Components/Homescreen/Navbar";
import PriceEstimationComponent from "../Components/Homescreen/PriceEstimation";

export const Home = () => {
    const [navBgColor, setNavBgColor] = useState("bg-transparent");
    const [navIconSize, setNavIconSize] = useState("h-12");

    const aboutRef = useRef<HTMLDivElement | null>(null);
    const whyChooseUsRef = useRef<HTMLDivElement | null>(null);
    const joinSegmentRef = useRef<HTMLDivElement | null>(null);
    const priceEstimationRef = useRef<HTMLDivElement | null>(null);
    const contactRef = useRef<HTMLDivElement | null>(null);


    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setNavBgColor("bg-[#012B48] transition duration-2000");
                setNavIconSize('h-10');
            } else {
                setNavBgColor("bg-transparent");
                setNavIconSize('h-12');
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className={`bg-[#04426E] text-white`}>
            <SvgDecorator />
            <Navbar
                navBgColor={navBgColor}
                navIconSize={navIconSize}
                joinSegmentRef={joinSegmentRef}
                priceEstimationRef={priceEstimationRef}
                aboutRef={aboutRef}
                whyChooseUsRef={whyChooseUsRef}
            />
            <div ref={joinSegmentRef}><HomeComponent ref={contactRef} /></div>
            <div ref={priceEstimationRef}><PriceEstimationComponent /></div>
            <JoinSegmentCloudComponent ref={contactRef} />
            <div ref={aboutRef}><About /></div>
            <div ref={whyChooseUsRef}><WhyChooseUsComponent /></div>
            <div ref={contactRef}><Contact/></div>
            <Footer />
        </div>
    );
};

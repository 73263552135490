import axios from 'axios';
import { useEffect, useState } from 'react';
import { logOut, updateUserPassword } from '../auth/Auth';
import EyeIcon from '../Elements/EyeIcon';
import Snackbar from './PasswordSnackBar';
import SaveButton from '../Elements/SaveButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

interface PasswordProps {
    activeTabExit: () => void;
}

function Password({ activeTabExit }: PasswordProps) {
    const [userName, setUserName] = useState("");
    const [open, setOpen] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchUserName = async () => {
            try {
                const response = await axios.get('/pool/name', { responseType: 'text' });
                setUserName(response.data);
            } catch (error) {
                console.error('Error fetching username:', error);
            }
        };

        fetchUserName();
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        resetForm();
    };

    const resetForm = () => {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setError("");
        setSuccessMessage("");
    };

    const handleChangePassword = async () => {
        setIsLoading(true)
        if (newPassword !== confirmPassword) {
            setError("New passwords do not match");
            setIsLoading(false)
            return;
        }
        try {
            await updateUserPassword(currentPassword, newPassword);
            setSuccessMessage("Password change successful. Please relogin.");
            setTimeout(() => {
                logOut(() => {
                    console.log("User logged out after password change");
                    handleClose();
                });
            }, 2000);
            setIsLoading(false)
        } catch (err) {
            console.error("Error changing password:", err);
            setError("Current password is incorrect or could not be updated.");
            setIsLoading(false)
        }
    };

    const handleCloseSnackbar = () => {
        setError('');
        setSuccessMessage('')
    };

    return (
        <div className="flex flex-col justify-center items-center text-center p-8">
            <div className="p-6 w-full max-w-xl flex flex-col justify-between items-center space-y-4 mx-auto">
                <div className="w-full text-start">
                    <label className="block text-black text-sm mb-2" htmlFor="current-password">
                        Old Password*
                    </label>
                    <div className="relative">
                        <input
                            id="current-password"
                            type={showCurrentPassword ? 'text' : 'password'}
                            className="w-full border border-gray-200 rounded-full p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter your current password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                        <EyeIcon isVisible={showCurrentPassword} onClick={() => setShowCurrentPassword(!showCurrentPassword)} />
                    </div>
                </div>

                <div className="w-full text-start">
                    <label className="block text-black text-sm mb-2" htmlFor="new-password">
                        New Password
                    </label>
                    <div className="relative">
                        <input
                            id="new-password"
                            type={showNewPassword ? 'text' : 'password'}
                            className="w-full border border-gray-200 rounded-full p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter your new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <EyeIcon isVisible={showNewPassword} onClick={() => setShowNewPassword(!showNewPassword)} />
                    </div>
                </div>

                <div className="w-full text-start">
                    <label className="block text-black text-sm mb-2" htmlFor="confirm-password">
                        Confirm New Password
                    </label>
                    <div className="relative">
                        <input
                            id="confirm-password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            className="w-full border border-gray-200 rounded-full p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Confirm your new password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <EyeIcon isVisible={showConfirmPassword} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                    </div>
                </div>
                <div className='flex justify-end items-end gap-2 w-full'>
                    <button
                        type="button"
                        onClick={activeTabExit}
                        className="gap-2 flex content-center items-center p-3 px-6 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100">
                        <p>Cancel</p>
                    </button>
                    <SaveButton isLoading={isLoading} onClick={handleChangePassword} >Save</SaveButton>
                </div>
            </div>
            <Snackbar
                error={error}
                successMessage={successMessage}
                onClose={handleCloseSnackbar}
            />
        </div>

    );
}

export default Password;

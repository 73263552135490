import React from "react";


export function copyToClipboard(s: string) {
    navigator.clipboard.writeText(s)
}

export function FormatDate(props: { date: string, spliter: React.ReactNode }) {
    const date = new Date(props.date)
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return (
        <>{day}/{month}/{year} {props.spliter} {hours}:{minutes}</>)
}

export function baseHashValue(n: any) {
    return n / 1;
}

export function hashFormat(n: any) {
    try {
        return Number(n).toFixed(7)
    } catch (e) { }
    return n
}
import React, { useState, ChangeEvent, FormEvent } from 'react';

interface FormData {
    fullName: string;
    email: string;
    subject: string;
    message: string;
    countryCode: string;
    phone: string;
}

interface FormErrors {
    fullName?: string;
    email?: string;
    subject?: string;
    message?: string;
    phone?: string;
}

interface CountryCodeOption {
    code: string;
    flag: string;
    name: string;
}

export const Contact: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
        fullName: '',
        email: '',
        subject: '',
        message: '',
        countryCode: '+31',
        phone: '',
    });

    const [errors, setErrors] = useState<FormErrors>({});

    const countryCodeOptions: CountryCodeOption[] = [
        { code: '+971', flag: '🇦🇪', name: 'United Arab Emirates' },
        { code: '+31', flag: '🇳🇱', name: 'Netherlands' },
    ];

    const validate = (): FormErrors => {
        let tempErrors: FormErrors = {};
        if (!formData.fullName) tempErrors.fullName = 'Full Name is required';
        if (!formData.email) {
            tempErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = 'Email is invalid';
        }
        if (!formData.message) tempErrors.message = 'Message is required';
        if (!formData.phone) tempErrors.phone = 'Phone is required';
        return tempErrors;
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            console.log(JSON.stringify(formData, null, 2));
            alert('Form submitted successfully');
            setFormData({ fullName: '', email: '', subject: '', message: '', countryCode: '+31', phone: '' }); // Reset form
        } else {
            setErrors(validationErrors);
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div className="container mx-auto py-4 md:py-16 px-8">
            <div className="flex flex-col md:flex-row justify-between items-start">
                <div className="w-full md:w-1/2 pr-4 mb-4">
                    <h2 className="text-2xl md:text-4xl font-bold mb-4">
                        PLEASE CONTACT US FOR ANY QUESTIONS
                    </h2>
                    <p className="mb-4">
                        PHONE NUMBER:
                        <br />
                        +971528364053
                    </p>
                    <p className="mb-4">
                        EMAIL ADDRESS:
                        <br />
                        <a className="text-yellow-500" href="mailto:asil@segments.ae">
                            asil@segments.ae
                        </a>
                    </p>
                    <p className="mb-4">
                        ADDRESS:
                        <br />
                        Based in Abu Dhabi, UAE.
                    </p>
                </div>
                <div className="w-full md:w-1/2">
                    <form onSubmit={handleSubmit}>
                        <div className="w-full max-w-md mx-auto space-y-4">
                            <input
                                className={`w-full p-2 bg-white bg-opacity-10 text-white rounded-md focus:outline-none focus:ring-2 ${errors.fullName ? 'ring-red-500' : 'focus:ring-blue-500'}`}
                                placeholder="Full Name"
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                type="text"
                            />
                            {errors.fullName && <span className="text-red-500 text-sm">{errors.fullName}</span>}

                            <input
                                className={`w-full p-2 bg-white bg-opacity-10 text-white rounded-md focus:outline-none focus:ring-2 ${errors.email ? 'ring-red-500' : 'focus:ring-blue-500'}`}
                                placeholder="E-mail"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                type="email"
                            />
                            {errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}

                            <div className="flex items-center space-x-2">
                                <select
                                    name="countryCode"
                                    className="bg-white bg-opacity-10 text-white p-2  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    value={formData.countryCode}
                                    onChange={handleChange}
                                >
                                    {countryCodeOptions.map((option) => (
                                        <option key={option.code} value={option.code}>
                                            {option.flag} {option.code}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    className={`w-full p-2 bg-white bg-opacity-10 text-white rounded-md focus:outline-none focus:ring-2 ${errors.phone ? 'ring-red-500' : 'focus:ring-blue-500'}`}
                                    placeholder="Phone"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </div>
                            {errors.phone && <span className="text-red-500 text-sm">{errors.phone}</span>}

                            <input
                                className="w-full p-2 bg-white bg-opacity-10 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Subject"
                                name="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                type="text"
                            />

                            <textarea
                                className={`w-full p-2 bg-white bg-opacity-10 text-white rounded-md focus:outline-none focus:ring-2 ${errors.message ? 'ring-red-500' : 'focus:ring-blue-500'} h-32`}
                                placeholder="Message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                            />
                            {errors.message && <span className="text-red-500 text-sm">{errors.message}</span>}

                            <div className="w-full flex justify-center md:justify-end">
                                <button
                                    type="submit"
                                    className="w-auto px-8 py-3 font-bold bg-[linear-gradient(98.9deg,_#FCBE13_-5.32%,_#EB910D_74.21%)] text-white rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                >
                                    SEND
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

import { initializeApp } from "firebase/app";
import { getAuth, signOut, updatePassword as firebaseUpdatePassword, signInWithEmailAndPassword } from "firebase/auth";

export interface FBuser {
    id: string;
    email: string;
    token: string;
}

const firebaseConfig = {
    apiKey: "AIzaSyB0MBucmCWJuATfsKysqWCHkCRnvSRb-CM",
    authDomain: "pool-1cbe0.firebaseapp.com",
    projectId: "pool-1cbe0",
    storageBucket: "pool-1cbe0.appspot.com",
    messagingSenderId: "292383967080",
    appId: "1:292383967080:web:66af18a93820f8978ab8dd",
    measurementId: "G-LWLP6N6SPQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig, "kas-pool-app");
export const auth = getAuth(app);
export default app;
export function logOut(success: () => void) {
    signOut(auth).then(() => {
        success()
        console.log("Signed out successfully")
    }).catch((e: any) => {
    });
}

export function signedUser(callback: (user: FBuser | null) => void) {
    refreshSignedUser(callback, false);
}

export function refreshSignedUser(callback: (user: FBuser | null) => void, refresh: boolean) {
    auth.currentUser?.getIdToken(refresh).then(tokenFound => {
        callback({ id: auth.currentUser?.uid ?? "", email: auth.currentUser?.email ?? "", token: tokenFound })
    }).catch((err) => { callback(null) })
}

export async function updateUserPassword(currentPassword: string, newPassword: string) {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("No user is currently signed in.");
    }
    try {
        if (typeof user.email === "string") {
            await signInWithEmailAndPassword(auth, user.email, currentPassword);
        }
        await firebaseUpdatePassword(user, newPassword);
    } catch (error) {
        console.error("Error updating password:", error);
        throw error;
    }
}
import { forwardRef, RefObject } from "react";

export const HomeComponent = forwardRef<HTMLDivElement>(
    
    (props, ref) => {
        const handleContactClick = () => {
            const sectionRef = ref as RefObject<HTMLDivElement>;
            if (sectionRef && sectionRef.current) {
                sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        };

    return (
        <div className='overflow-hidden'>
            <div className="md:container mx-auto px-4 py-16 ">
                <div className="absolute inset-0" data-aos="fade-right" style={{
                    background: `radial-gradient(30.24% 41.24% at 10% 50%, rgba(67, 165, 233, 0.7) 0%, rgba(29, 101, 151, 0) 100%),url('/assets/ic_ring1.png') 0% 50% / 30% no-repeat`
                }}>
                </div>
                <div className="flex flex-col md:flex-row justify-between items-center relative h-screen">

                    <div className="text-left w-full md:w-50% md:mt-[-8%] mt-4">
                        <h1 className="text-2xl mt-6 md:mt-0 md:text-6xl font-bold mb-16 ">
                            UAE’S FIRST KAS CRYPTO MINING POOL
                        </h1>
                        <p className="mt-4 text-md md:text-2xl mb-8 md:mb-16">
                            Join the UAE's first KaS crypto mining pool and be part of the future of decentralized blockchain technology. Maximize your mining potential with fast, secure, and efficient rewards.
                        </p>
                        <button
                            onClick={handleContactClick}
                            className="w-auto px-4 md:px-8 py-2 md:py-4 font-bold bg-[linear-gradient(98.9deg,_#FCBE13_-5.32%,_#EB910D_74.21%)] text-white rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-500"
                        >
                            CONTACT US
                        </button>
                    </div>
                    <div className="w-full md:w-50%">
                        <img
                            alt="Crypto Mining Illustration"
                            className="w-full"
                            src="/assets/ic_landing.svg"
                            style={{
                                transform: 'scale(1.4)',
                                transformOrigin: 'right',
                                position: 'relative',
                                right: '-30%',
                                width: 'auto',
                                height: 'auto',
                            }}
                        />

                    </div>
                </div>

            </div>
        </div>
    );
}
);
import { useContext } from "react";
import { appContext } from "../../App";
import { ChangeEvent } from "react";
import axios from "axios";

const Switcher: React.FC = () => {

    const { authUserName, setAuthUserName, authUserEmail, setAuthUserEmail, authList } = useContext(appContext);



    function handleSelectChange(event: ChangeEvent<HTMLSelectElement>): void {
        setAuthUserEmail(event.target.value)
        axios.defaults.headers.common['userId'] = event.target.value;
        authList.forEach((n: any) => {
            if (n.email == event.target.value) {
                setAuthUserName(n.userName)
                return;
            }
        });
    }

    if (authList.length == 0) return <></>

    return (<div style={{ width: "100%", padding: "10px 5px", textAlign: "right" }}>
        <select value={authUserEmail} onChange={handleSelectChange}>
            {authList.map((n: any) => (
                <option value={n.email} key={n.email}>{n.userName}</option>
            ))}
        </select>
    </div>);
};


export default Switcher;
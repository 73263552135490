import React, { createContext, useState, useEffect, ReactNode } from 'react';

interface CoinContextType {
    selectedCoin: string;
    setSelectedCoin: (coin: string) => void;
}

export const CoinContext = createContext<CoinContextType | undefined>(undefined);

export const CoinProvider = ({ children }: { children: ReactNode }) => {
    const [selectedCoin, setSelectedCoin] = useState(() => {
        const storedCoin = localStorage.getItem('selectedCoin');
        return storedCoin ? storedCoin : 'BTC';
    });

    useEffect(() => {
        localStorage.setItem('selectedCoin', selectedCoin);
    }, [selectedCoin]);

    return (
        <CoinContext.Provider value={{ selectedCoin, setSelectedCoin }}>
            {children}
        </CoinContext.Provider>
    );
};

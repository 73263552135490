import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { CoinContext } from '../../context/CoinContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faHome } from '@fortawesome/free-solid-svg-icons';
import { FormatDate } from '../../Helpers/Utils';
import { useNavigate } from 'react-router-dom';
import Wallet from './Wallet';

interface WalletType {
    id: string;
    name: string;
}

interface WalletRow {
    userName: string;
    wallet: string;
    active: boolean;
    changeTime: string;
    walletType: string;
}
interface settingProps {
    activeTabExit: () => void;
}

function Settings({ activeTabExit }: settingProps) {

    // function fetchWalletTypes(coin: string) {
    //     axios.get<WalletType[]>(`/pool/wallet/type?coin=${coin}`)
    //         .then(response => {
    //             const sortedWalletTypes = response.data.sort((a, b) => {
    //                 if (a.name === 'KAS') return -1;
    //                 if (b.name === 'KAS') return 1;
    //                 return 0;
    //             });
    //             setWalletTypes(sortedWalletTypes);
    //         })
    //         .catch(error => {
    //             console.error("Error fetching wallet types:", error);
    //             showSnackbar('Failed to load wallet types', 'error');
    //         });
    // }
    
    return (
        <div className="flex flex-col justify-center  p-2 md:p-8 md:items-center">
            <Wallet/>
            <div className='w-full flex justify-center'>
                <button
                    type="button"
                    onClick={activeTabExit}
                    className="gap-2 flex content-center items-center py-3 my-3 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100">
                    <FontAwesomeIcon icon={faHome} />
                    <p>Back to Home</p>
                </button>
            </div>
        </div>
    );
}

export default Settings;

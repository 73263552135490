import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SignUpScreen: React.FC = () => {
    const navigate = useNavigate();
    const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
    const [visibleConfirmPassword, setVisibleConfirmPassword] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [alertSev, setAlertSev] = useState("error");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTermsAccepted(e.target.checked);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrorMessage(null);
        setPasswordError(null);

        const validations = [
            {
                condition: !formData.username.trim() || !formData.email.trim(),
                message: 'Username and email cannot be empty',
            },
            {
                condition: !/^[A-Za-z0-9]+$/.test(formData.username),
                message: 'Username can only contain letters and numbers',
            },
            {
                condition: formData.password.length < 8,
                message: 'Password must be at least 8 characters long',
            },
            {
                condition: formData.password !== formData.confirmPassword,
                message: "Passwords don't match",
            },
            {
                condition: !termsAccepted,
                message: 'Please accept the terms and conditions',
            },
        ];

        for (const validation of validations) {
            if (validation.condition) {
                setErrorMessage(validation.message);
                return;
            }
        }

        setIsLoading(true);
        try {
            const response = await axios.post('/pool/auth/register', {
                userName: formData.username,
                email: formData.email,
                password: formData.password,
            });
            if (response.data.status) {
                setAlertSev("success");
                setErrorMessage(response.data.message);
                navigate('/login');
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (error: any) {
            if (error.response) {
                setErrorMessage('Error: Unable to register.');
            } else if (error.request) {
                setErrorMessage('Network error: No response received from server');
            } else {
                setErrorMessage('An error occurred while processing your request');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setErrorMessage(null);
    };


    return (
        <>
            <div
                className="flex items-center justify-center min-h-screen"
                style={{
                    backgroundImage: `url('/assets/user_background.jpeg')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <div className="absolute inset-0 bg-[#04426E] opacity-60"></div>
                <div className="bg-white bg-opacity-20 backdrop-blur-md p-8 m-4 rounded-lg shadow-lg w-full max-w-md md:max-w-lg lg:max-w-md">
                    <h2 className="text-3xl font-bold text-center text-white mb-6">SIGN UP</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-white mb-1" htmlFor="name">Name *</label>
                            <input
                                className="w-full p-2 rounded bg-white bg-opacity-90 text-gray-700 focus:outline-none"
                                type="text"
                                id="name"
                                name="username"
                                placeholder="Username"
                                value={formData.username.toLocaleLowerCase()}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-white mb-1" htmlFor="email">Email *</label>
                            <input
                                className="w-full p-2 rounded bg-white bg-opacity-90 text-gray-700 focus:outline-none"
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4 relative">
                            <label className="block text-white mb-1" htmlFor="password">Password *</label>
                            <div className="relative">
                                <input
                                    id="password"
                                    type={visiblePassword ? 'text' : 'password'}
                                    className="w-full p-2 rounded bg-white bg-opacity-90 text-gray-700 focus:outline-none"
                                    name="password"
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                                <button
                                    type="button"
                                    data-hs-toggle-password='{"target": "#password"}'
                                    className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500"
                                    onClick={() => setVisiblePassword(!visiblePassword)}
                                >
                                    <svg className="shrink-0 size-3.5" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path className="hs-password-active:hidden" d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                        <path className="hs-password-active:hidden" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                                        <path className="hs-password-active:hidden" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                                        <line className="hs-password-active:hidden" x1="2" x2="22" y1="2" y2="22"></line>
                                        <path className="hidden hs-password-active:block" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                                        <circle className="hidden hs-password-active:block" cx="12" cy="12" r="3"></circle>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="mb-4 relative">
                            <label className="block text-white mb-1" htmlFor="confirmPassword">Confirm Password *</label>
                            <div className="relative">
                                <input
                                    id="confirmPassword"
                                    type={visibleConfirmPassword ? 'text' : 'password'}
                                    className="w-full p-2 rounded bg-white bg-opacity-90 text-gray-700 focus:outline-none"
                                    placeholder="Enter confirm password"
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                />
                                <button
                                    type="button"
                                    data-hs-toggle-password='{"target": "#confirmPassword"}'
                                    className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500"
                                    onClick={() => setVisibleConfirmPassword(!visibleConfirmPassword)}
                                >
                                    <svg className="shrink-0 size-3.5" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path className="hs-password-active:hidden" d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                        <path className="hs-password-active:hidden" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                                        <path className="hs-password-active:hidden" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                                        <line className="hs-password-active:hidden" x1="2" x2="22" y1="2" y2="22"></line>
                                        <path className="hidden hs-password-active:block" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                                        <circle className="hidden hs-password-active:block" cx="12" cy="12" r="3"></circle>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className='mb-2 text-white text-sm'>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={termsAccepted}
                                    onChange={handleCheckboxChange}
                                    style={{ marginRight: '8px' }}
                                />
                                <span className='cursor-pointer'>I accept the terms and conditions</span>
                            </label>
                        </div>
                        <button
                            type="submit"
                            className="w-full p-3 rounded-lg font-bold hover:opacity-90 transition duration-300"
                            style={{
                                background: 'linear-gradient(98.9deg, #FCBE13 -5.32%, #EB910D 74.21%)',
                                backgroundClip: 'padding-box',
                                border: 'none',
                                color: 'white'
                            }}
                            disabled={!termsAccepted}
                        >
                            {isLoading &&
                                <svg
                                    aria-hidden="true"
                                    role="status"
                                    className="inline w-4 h-4 mr-2 text-white animate-spin"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                </svg>
                            }
                            SIGN UP
                        </button>
                    </form>
                    <div className="text-center mt-6">
                        <p className="text-white text-sm">
                            Already have an account? <span className="text-white font-bold cursor-pointer" onClick={() => navigate('/login')}> Sign in →</span>
                        </p>
                    </div>

                </div>

            </div>
            {errorMessage && (
                <div
                    className={`flex-1 text-sm font-medium ${alertSev === 'success' ? 'bg-green-100 border border-green-400 text-green-700' : 'bg-red-100 border border-red-400 text-red-700'} fixed bottom-5 left-10 p-4 rounded-md shadow-lg w-full max-w-sm flex justify-between`}
                    role="alert"
                >
                    <span>{errorMessage}</span>
                    <button
                        className="text-gray-500 hover:text-gray-700"
                        onClick={handleCloseSnackbar}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-5 h-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
            )}
        </>
    );
};

export default SignUpScreen;

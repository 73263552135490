import React from 'react';

const minerData = [
    {
        model: "Antminer S19",
        hashrate: "95 TH/s",
        power: "3250 W",
        coins: "Bitcoin (BTC)",
        profit: "$12/day"
    },
    {
        model: "Whatsminer M30S",
        hashrate: "86 TH/s",
        power: "3344 W",
        coins: "Bitcoin (BTC)",
        profit: "$11/day"
    },
    {
        model: "Innosilicon T3+",
        hashrate: "67 TH/s",
        power: "3300 W",
        coins: "Bitcoin (BTC)",
        profit: "$8/day"
    },
    {
        model: "AvalonMiner 1246",
        hashrate: "90 TH/s",
        power: "3420 W",
        coins: "Bitcoin (BTC)",
        profit: "$10/day"
    },
    {
        model: "Antminer S17",
        hashrate: "56 TH/s",
        power: "2520 W",
        coins: "Bitcoin (BTC)",
        profit: "$6/day"
    },
    {
        model: "Ebang Ebit E11++",
        hashrate: "44 TH/s",
        power: "1980 W",
        coins: "Bitcoin (BTC)",
        profit: "$5/day"
    },
    {
        model: "Bitfury Tardis",
        hashrate: "80 TH/s",
        power: "6300 W",
        coins: "Bitcoin (BTC)",
        profit: "$9/day"
    }
]


const MinerTable: React.FC = () => {
    return (
        <div className="container mx-auto py-16 sm:py-32 px-4">
            <div className="flex flex-col sm:flex-row justify-between items-center mb-8 space-y-4 sm:space-y-0">
                <h1 className="text-xl sm:text-3xl font-bold">MINER RANKING</h1>
            </div>
            <div className="overflow-x-auto">
                <table className="w-full md:table hidden items-center justify-between table-auto border-collapse bg-white bg-opacity-10 shadow-lg rounded-lg overflow-hidden">
                    <thead className="text-gray-200 uppercase text-sm leading-normal p-2">
                        <tr className='bg-white bg-opacity-10'>
                            <th className="px-6 py-3 text-start">MODEL</th>
                            <th className="px-6 py-3 text-start">HASHRATE</th>
                            <th className="px-6 py-3 text-start">POWER</th>
                            <th className="px-6 py-3 text-start">COINS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {minerData.map((miner, index) => (
                            <tr key={index} className="text-gray-200 text-sm border-b border-gray-500">
                                <td className="px-6 py-4 flex items-center font-semibold">
                                    <i className="fas fa-microchip text-blue-500 mr-2"></i>
                                    {miner.model}
                                </td>
                                <td className="px-6 py-4">{miner.hashrate}</td>
                                <td className="px-6 py-4">{miner.power}</td>
                                <td className="px-6 py-4 flex items-center">{miner.coins}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {minerData.map((miner, index) => (
                    <table className="md:hidden min-w-full bg-opacity-10 shadow-lg rounded-lg overflow-hidden my-1">
                        <tbody className='text-center'>
                            <tr key={index} className="text-sm border-b border-gray-500">
                                <th className="px-6 py-3 bg-white bg-opacity-10 w-1/2">MODEL</th>
                                <td className="px-6 py-4 w-1/2 bg-white bg-opacity-5 ">{miner.model}</td>
                            </tr>
                            <tr key={index} className="text-sm border-b border-gray-500">
                                <th className="px-6 py-3 bg-white bg-opacity-10 w-1/2">HASHRATE</th>
                                <td className="px-6 py-4 w-1/2 bg-white bg-opacity-5">{miner.hashrate}</td>
                            </tr>
                            <tr key={index} className="text-sm border-b border-gray-500">
                                <th className="px-6 py-3 bg-white bg-opacity-10 w-1/2">POWER</th>
                                <td className="px-6 py-4 w-1/2 bg-white bg-opacity-5">{miner.power}</td>
                            </tr>
                            <tr key={index} className="text-sm border-b border-gray-500">
                                <th className="px-6 py-3 bg-white bg-opacity-10 w-1/2">COINS</th>
                                <td className="px-6 py-4 w-1/2 bg-white bg-opacity-5">{miner.coins}</td>
                            </tr>
                        </tbody>
                    </table>
                ))}
            </div>
        </div>
    );
};

export default MinerTable;

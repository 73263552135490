import React, { useState } from "react";
import MinerTable from "./MInerData";

interface PriceEstimationComponentProps { }

const PriceEstimationComponent: React.FC<PriceEstimationComponentProps> = () => {
    const [monthlyEarnings, setMonthlyEarnings] = useState<number>(65000);
    const [estimatedPurchasePrice, setEstimatedPurchasePrice] = useState<number>((monthlyEarnings * 12) / 1.4);

    const handleMonthlyEarningsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMonthlyEarnings(parseFloat(e.target.value) || 0);
        setEstimatedPurchasePrice((parseFloat(e.target.value) || 0) * 12 / 1.4);
    };

    const handleEstimatedPurchasePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEstimatedPurchasePrice(parseFloat(e.target.value) || 0);
        setMonthlyEarnings((parseFloat(e.target.value) || 0) * 1.4 / 12);
    };

    return (
        <>
            <h2 className="text-xl sm:text-4xl font-bold text-center py-8">
                HOW MUCH YOU WANT TO EARN MONTHLY
            </h2>
            <div className="text-center w-full bg-white py-8 md:py-16 bg-opacity-10">
                <div className="flex flex-col lg:flex-row justify-center items-center lg:items-stretch lg:space-x-8 space-y-6 lg:space-y-0 w-full">
                    <div className="bg-white px-6 py-4 rounded shadow w-11/12 lg:w-1/5">
                        <p className="text-gray-500">
                            MONTHLY EARNINGS:
                        </p>
                        <input
                            type="number"
                            value={monthlyEarnings}
                            onChange={handleMonthlyEarningsChange}
                            className="text-2xl font-bold text-gray-600 w-full  focus:outline-none text-center"
                        />
                    </div>
                    <div className="text-gray-100 bg-transparent px-6 py-4 rounded w-full lg:w-1/5">
                        <p>
                            ESTIMATED PURCHASE PRICE
                        </p>
                        <input
                            type="number"
                            value={estimatedPurchasePrice}
                            onChange={handleEstimatedPurchasePriceChange}
                            className="text-2xl font-bold text-gray-100 w-full bg-transparent  focus:outline-none text-center"
                        />
                    </div>
                </div>
            </div>
            <MinerTable />
        </>
    );
};

export default PriceEstimationComponent;
import React, { useState } from "react";

interface Reward {
    date: string;
    amount: number;
}

interface PayoutEntry {
    createdTime: string;
    payoutAsBtc: boolean;
    amount: number;
    valueInBtc: number;
    rewards: Reward[];
    transactionStatus: string;
    transactionId: string;
}

interface RevenuePayoutsProps {
    payouts: PayoutEntry[];
    coin: string;
}

const RevenuePayouts: React.FC<RevenuePayoutsProps> = ({ payouts, coin }) => {
    const [showTxIdIndex, setShowTxIdIndex] = useState<number | null>(null);
    const [rewardToggle, setRewardToggle] = useState<number | null>(null);

    const toggleTxIdRow = (index: number) => {
        setShowTxIdIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const toggleRewardToggle = (index: number) => {
        setRewardToggle((prevIndex) => (prevIndex === index ? null : index));
    };

    const sortedPayouts = payouts.sort((a, b) => {
        return new Date(b.createdTime).getTime() - new Date(a.createdTime).getTime();
    });

    return (
        <div className="p-4">
            <div className="overflow-x-auto w-full">
                <table className="min-w-full text-gray-500 text-center">
                    <thead className="text-xs text-gray-700 uppercase bg-[#D5EEFF] border">
                        <tr>
                            <th scope="col" className="px-1 py-2 md:px-6 md:py-3 text-xs">Date</th>
                            <th scope="col" className="px-1 py-2 md:px-6 md:py-3 text-xs">Amount</th>
                            <th scope="col" className="px-1 py-2 md:px-6 md:py-3 text-xs">Status</th>
                        </tr>
                    </thead>
                    <tbody className="space-y-4">
                        {payouts.length === 0 ? (
                            <tr>
                                <td colSpan={4} className="text-center py-4">No data available</td>
                            </tr>
                        ) : (
                            sortedPayouts.map((entry, index) => (
                                <React.Fragment key={index}>
                                    <tr className="bg-white border m-1 mb-4">
                                        <td className="px-1 py-2 md:px-6 md:py-3 text-xs md:text-sm">
                                            {new Date(entry?.createdTime).toLocaleDateString()}
                                        </td>
                                        <td className="px-1 py-2 md:px-6 md:py-3 text-xs md:text-sm">
                                            {entry?.amount.toFixed(8)}
                                        </td>
                                        <td className="px-1 py-2 md:px-6 md:py-3 text-xs md:text-sm">
                                            {entry?.transactionStatus && (
                                                <>
                                                    <span
                                                        className="text-blue-500 cursor-pointer text-xs md:text-sm"
                                                        onClick={() => {
                                                            if (entry?.transactionStatus === 'CREATED' || entry?.transactionStatus === 'ACCUMULATED' || entry?.transactionStatus === 'TRANSFER_COMPLETED') {
                                                                toggleRewardToggle(index);
                                                            } else {
                                                                setRewardToggle(null);
                                                            }
                                                        }}
                                                    >
                                                        {entry?.transactionStatus === 'TRANSFER_COMPLETED' && 'TRANSFER COMPLETED' }
                                                        {entry?.transactionStatus === 'INVALID_WALLET' && 'INVALID WALLET'}
                                                        {entry?.transactionStatus === 'CREATED' && 'CREATED'}
                                                        {entry?.transactionStatus === 'ACCUMULATED' && 'ACCUMULATED'}
                                                    </span>
                                                    |{' '}
                                                    <span
                                                        className="text-gray-800 cursor-pointer text-xs md:text-sm"
                                                        onClick={() => toggleTxIdRow(index)}
                                                    >
                                                        TXID
                                                    </span>
                                                </>
                                            )}
                                        </td>
                                    </tr>

                                    {rewardToggle === index && (
                                        <tr>
                                            <td colSpan={4} className="border border-red-200 py-2 text-sm text-gray-400 font-normal">
                                                <div className="flex flex-col items-center">
                                                    {entry?.rewards.map((reward, rewardIndex) => (
                                                        <div key={rewardIndex} className="flex justify-around w-full px-8 text-xs">
                                                            <span>{new Date(reward.date).toLocaleDateString()}</span>
                                                            <span>{reward.amount.toFixed(8)} {coin}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </td>
                                        </tr>
                                    )}

                                    {showTxIdIndex === index && (
                                        <tr>
                                            <td colSpan={4} className="px-2 py-4 text-sm text-gray-400 text-end font-normal">
                                                {entry?.transactionId}
                                            </td>
                                        </tr>
                                    )}

                                </React.Fragment>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RevenuePayouts;

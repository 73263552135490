import { useEffect, useRef, useState, useContext } from 'react';
import UserMenu from '../Elements/UserMenu';
import HomeData from './home/HomeData';
import Workers from './worker/Workers';
import RevenuePage from './revenue/RevenuePage';
import Settings from '../settings/Settings';
import Password from '../ChangePassword/Password';
import CoinDropdown from '../Elements/CoinDropDown';
import { CoinContext, CoinProvider } from '../../context/CoinContext';
import Switcher from '../auth/Switcher';

function WorkerData() {
    const [activeTab, setActiveTab] = useState('HOME');
    const [settingsActive, setSettingsActive] = useState(false);
    const [passwordActive, setPasswordActive] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const coinContext = useContext(CoinContext);

    if (!coinContext) {
        throw new Error('CoinContext must be used within a CoinProvider');
    }

    const { selectedCoin, setSelectedCoin } = coinContext;

    const currencyOptions = [
        { theme: 'bitcoin', code: 'BTC', symbol: 'BTC', image: '/assets/currency-btc.png' },
        { theme: 'kaspa', code: 'KAS', symbol: 'KAS', image: '/assets/currency-kas.png' },
    ];

    useEffect(() => {
        if (settingsActive) {
            setActiveTab('PAYOUT SETTINGS');
            setPasswordActive(false);
        }
    }, [settingsActive]);

    useEffect(() => {
        if (passwordActive) {
            setActiveTab('PASSWORD');
            setSettingsActive(false);
        }
    }, [passwordActive]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const resetToHome = () => {
        setActiveTab('HOME');
        setSettingsActive(false);
        setPasswordActive(false);
    };

    const NavigateToPassword = () => {
        setActiveTab('PAYOUT SETTINGS');
    }

    const toggleCoinDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    const handleCoinSelect = (code: string) => {
        setSelectedCoin(code);
        setIsDropdownOpen(false);
    };

    return (

        <div className="bg-gray-50 h-fit min-h-screen">
            <Switcher />
            <nav className="flex-no-wrap flex w-full items-center justify-between z-10 bg-gradient-to-r from-[#0880D4] to-[#04426E]">
                <div className="container mx-auto flex w-full flex-wrap px-3 py-2">
                    <img
                        src="/assets/logo_col.svg"
                        className="h-7 md:h-12 cursor-pointer"
                        alt="Logo"
                        onClick={resetToHome}
                    />
                    <ul className="list-style-none hidden md:flex justify-center items-center flex-grow ">
                        {settingsActive ? (
                            <li className="px-4">
                                <button className="transition duration-200 px-4 py-2 text-md border-b border-white text-white bg-white bg-opacity-10 rounded-md font-bold">
                                    PAYOUT SETTINGS
                                </button>
                            </li>
                        ) : passwordActive ? (
                            <li className="px-4">
                                <button className="transition duration-200 px-4 py-2 text-md border-b border-white text-white bg-white bg-opacity-10 rounded-md font-bold">
                                    PASSWORD
                                </button>
                            </li>
                        ) : (
                            ['HOME', 'WORKERS', 'REVENUE'].map((tab) => (
                                <li key={tab} className="px-4">
                                    <button
                                        onClick={() => setActiveTab(tab)}
                                        className={`transition duration-200 px-4 py-2 text-md ${activeTab === tab
                                            ? 'border-b border-white text-white bg-white bg-opacity-10 rounded-md'
                                            : 'text-white/90 hover:text-white'
                                            } font-bold`}
                                    >
                                        {tab}
                                    </button>
                                </li>
                            ))
                        )}
                    </ul>
                    <div className='flex ml-auto'>
                        <CoinDropdown
                            selectedCoin={selectedCoin}
                            currencyOptions={currencyOptions}
                            onSelect={handleCoinSelect}
                        />
                        <UserMenu
                            settingsActive={settingsActive}
                            setSettingsActive={setSettingsActive}
                            passwordActive={passwordActive}
                            setPasswordActive={setPasswordActive}
                        />
                    </div>
                </div>
            </nav>

            <div className="container mx-auto">
                <ul className="list-style-none md:hidden w-full flex items-center justify-center flex-grow my-3 text-xs">
                    {settingsActive ? (
                        <li className="px-4">
                            <button className="bg-gray-100 text-gray-800 py-1 px-2 border-none border-gray-400">
                                PAYOUT SETTINGS
                            </button>
                        </li>
                    ) : passwordActive ? (
                        <li className="px-4">
                            <button className="bg-gray-100 text-gray-800 py-1 px-2 border-none border-gray-400">
                                PASSWORD
                            </button>
                        </li>
                    ) : (
                        ['HOME', 'WORKERS', 'REVENUE'].map((tab) => (
                            <li key={tab}>
                                <button
                                    onClick={() => setActiveTab(tab)}
                                    className={`text-gray-800 py-1 px-2 border-gray-400  ${activeTab === tab
                                        ? 'text-blue-950 border-b border-blue-900'
                                        : 'bg-white'
                                        }`}
                                >
                                    {tab}
                                </button>
                            </li>
                        ))
                    )}
                </ul>
                {activeTab === 'HOME' && <HomeData NavigateToPassword={NavigateToPassword} />}
                {activeTab === 'WORKERS' && <Workers />}
                {activeTab === 'REVENUE' && <RevenuePage />}
                {activeTab === 'PAYOUT SETTINGS' && <Settings activeTabExit={resetToHome} />}
                {activeTab === 'PASSWORD' && <Password activeTabExit={resetToHome} />}
            </div>
        </div>
    );
}

export default WorkerData;

import React, { useState, useEffect, useContext } from 'react';
import { FormatDate } from '../../Helpers/Utils';
import axios from 'axios';
import { CoinContext } from '../../context/CoinContext';
import { DotLoader } from '../Elements/LogoLoader';

interface Props { }


interface WalletRow {
    userName: string;
    wallet: string;
    active: boolean;
    changeTime: string;
    walletType: string;
    id: string;
}

function Wallet(props: Props) {
    const [rows, setRows] = useState<WalletRow[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [snackbar, setSnackbar] = useState<{ message: string; type: 'success' | 'error' } | null>(null);
    const [wallet, setWallet] = useState<string>('');
    const [walletInput, setWalletInput] = useState<string>('');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<WalletRow | null>(null);
    const coinContext = useContext(CoinContext);
    const [selectedCoin, setSelectedCoin] = useState<string | null>(null)
    const [saveButtonLoading, setSaveButtonLoading] = useState(false)


    useEffect(() => {
        if (coinContext) {
            const coin = coinContext.selectedCoin;
            readWalletInfo(coin);
            setSelectedCoin(coin)
        }
    }, [coinContext]);

    function readWalletInfo(coin: string) {
        setIsLoading(true);
        getWalletInfo(coin, walletRows => {
            const activeWallet = walletRows.find(row => row.active)?.wallet || '';
            setWallet(activeWallet);
            setRows(walletRows.reverse());
            setIsLoading(false);
        });
    }

    function getWalletInfo(coin: string, callback: (data: WalletRow[]) => void) {
        axios.get(`/pool/wallet?coin=${coin}`)
            .then(response => {
                callback(response.data);
            }).catch(e => {
                console.error("Error fetching wallet info:", e);
                callback([]);
                showSnackbar('Failed to load wallet info', 'error');
            });
    }

    const showSnackbar = (message: string, type: 'success' | 'error') => {
        setSnackbar({ message, type });
        setTimeout(() => setSnackbar(null), 3000);
    };

    const handleEditWalletOpenModal = (row: WalletRow | null) => {
        if (!row) {
            setWalletInput('');
            setSelectedRow(null);
        } else {
            const walletValue = row.wallet || '';
            const walletTypeValue = row.walletType || '';
            setWalletInput(walletValue);
            setSelectedRow(row);
        }

        setIsOpen(true);
    };



    const handleSave = (e : any) => {
        e.preventDefault()
        if (!selectedRow) {
            saveWallet(walletInput, coinContext?.selectedCoin || '',  (stat, response) => {
                setIsLoading(false);
                if (stat) {
                    showSnackbar("Wallet updated successfully!", 'success');
                    readWalletInfo(coinContext?.selectedCoin || '');
                } else {
                    showSnackbar(response?.message || "An error occurred.", 'error');
                }
            },e);
            return;
        }
        
        setIsLoading(true);
        setSaveButtonLoading(true)
        updateWallet(walletInput, coinContext?.selectedCoin || '', (stat, response) => {
            setIsLoading(false);

            if (stat) {
                showSnackbar("Wallet updated successfully!", 'success');
                readWalletInfo(coinContext?.selectedCoin || '');
                setIsOpen(false);
                setSaveButtonLoading(false)
            } else {
                showSnackbar(response?.message || "An error occurred.", 'error');
                setSaveButtonLoading(false)
            }
        }, selectedRow);
    };




    function updateWallet(walletId: string, currency: string, callback: (stat: boolean, response?: { message: string }) => void, row: WalletRow) {
        if (!walletId) {
            callback(false, { message: 'Wallet address cannot be empty' });
            return;
        }


        axios.post(`/pool/wallet/add?coin=${currency}`, { walletId, currency, walletType: currency, id: row.id })
            .then(response => {
                const responseMessage = response?.data?.message || "An error occurred";
                const st = response?.data?.status || false;
                callback(st, { message: responseMessage });
                setSaveButtonLoading(false)
            })
            .catch(error => {
                console.error("Error saving wallet:", error);
                const responseMessage = error.response?.data?.message || "An error occurred";
                callback(false, { message: responseMessage });
            });
    }

    function saveWallet(walletId: string, currency: string, callback: (stat: boolean, response?: { message: string }) => void, e: any) {

        if (!walletId) {
            callback(false, { message: 'Wallet address cannot be empty' });
            return;
        }

        axios.post(`/pool/wallet/add?coin=${currency}`, { walletId, currency, walletType: currency })
            .then(response => {
                const responseMessage = response?.data?.message || "An error occurred";
                const st = response?.data?.status || false;
                callback(st, { message: responseMessage });
            })
            .catch(error => {
                console.error("Error saving wallet:", error);
                const responseMessage = error.response?.data?.message || "An error occurred";
                callback(false, { message: responseMessage });
            });
    }

    const handleModalClose=(e : any)=>{
        e.preventDefault()
        setIsOpen(false)
    }


    return (
        <>
            {isLoading ? (
                <p><DotLoader/></p>
            ) : (

                rows.length !== 0 ? rows.map((row, i) => (
                    <div key={i} className="bg-white shadow border-gray-200 p-4 md:p-6 md:w-3/4 w-full flex flex-col md:flex-row justify-between rounded-lg">
                        <div className="text-start break-words">
                            <p className='text-gray-500 font-bold text-base md:text-lg'>Payout Option</p>
                            <p className="text-sm text-left text-gray-900 ">
                                {row.walletType}
                            </p>
                            <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200" />
                            <p className="text-gray-500 font-bold text-base md:text-lg">Wallet Address</p>
                            <p className="text-gray-500 text-sm md:text-base">{row.wallet || 'Payout address has not been added'}</p>
                            <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200" />
                            <p className="text-sm text-gray-600">
                                Added: <FormatDate date={row.changeTime} spliter={<></>} />
                            </p>
                            <p className={`text-sm ${row.active ? "text-green-500" : "text-red-500"}`}>
                                Status: {row.active ? "Active" : "Inactive"}
                            </p>
                        </div>
                        <div className='mt-2'>
                            <button
                                className="bg-white text-sm hover:bg-blue-100 text-blue-800 py-2 px-4 rounded shadow"
                                onClick={() => handleEditWalletOpenModal(row)}
                            >
                                Update
                            </button>
                        </div>
                    </div>
                ))
                    :
                    <>
                        <div className="bg-white shadow border-gray-200 p-4 md:p-6 md:w-3/4 w-full flex flex-col md:flex-row justify-between rounded-lg">
                            <div className="text-start">
                                <p className='text-gray-500 font-bold text-base md:text-lg'>Payout Option</p>
                                <p className="text-sm text-left text-gray-900">
                                    {selectedCoin}
                                </p>
                            </div>
                            <div>
                                <button
                                    className="bg-white text-sm hover:bg-blue-100 text-blue-800 py-2 px-4 rounded shadow"
                                    onClick={() => handleEditWalletOpenModal(null)}
                                >
                                    ADD WALLET
                                </button>
                            </div>
                        </div>
                    </>
            )}

            <div
                className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 flex justify-center items-center ${modalIsOpen ? 'block' : 'hidden'}`}
            >
                <div className="bg-white rounded-sm shadow-md w-full max-w-md sm:max-w-lg p-4 sm:p-6 mx-4 sm:mx-0">
                    <h2 className="text-lg font-bold text-center mb-4">{rows.length !== 0 ? "UPDATE WALLET" : "ADD WALLET"}</h2>
                    <form>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="wallet-type">
                                Payout Option
                            </label>
                            {selectedCoin !== "KAS" ?
                                <span className='border-b border-gray-300 text-gray-900 block w-full p-2.5 pr-5'>BTC</span>
                                :
                                <span className='border-b border-gray-300 text-gray-900 block w-full p-2.5 pr-5'>KAS</span>
                            }

                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="wallet-input">
                                Wallet Address
                            </label>
                            <input
                                className="border-b border-gray-300 text-gray-900 block w-full p-2.5 pr-5"
                                type="text"
                                id="wallet-input"
                                value={walletInput}
                                onChange={(e) => setWalletInput(e.target.value)}
                            />
                        </div>
                        {saveButtonLoading ?
                            <button
                                className="py-2 px-6 rounded font-bold hover:opacity-90 transition duration-300 text-white w-full sm:w-auto"
                                style={{ background: 'linear-gradient(98.9deg, #FCBE13 -5.32%, #EB910D 74.21%)' }}
                            >
                                Saving...
                            </button> :
                            <button
                                className="py-2 px-6 rounded font-bold hover:opacity-90 transition duration-300 text-white w-full sm:w-auto"
                                style={{ background: 'linear-gradient(98.9deg, #FCBE13 -5.32%, #EB910D 74.21%)' }}
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        }
                        <button
                            className="bg-transparent hover:bg-gray-200 text-gray-700 border border-gray-200 py-2 px-4 rounded ml-0 sm:ml-4 mt-4 sm:mt-0 w-full sm:w-auto"
                            onClick={handleModalClose}
                        >
                            Cancel
                        </button>
                    </form>
                </div>
            </div>

            {snackbar && (
                <div className={`fixed bottom-5 right-5 p-4 rounded shadow ${snackbar.type === 'success' ? 'bg-green-500' : 'bg-red-500'} text-white`}>
                    {snackbar.message}
                </div>
            )}
        </>
    );
}

export default Wallet;
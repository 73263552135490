import React, { useContext, useEffect, useRef, useState } from 'react';
import { logOut } from '../auth/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { appContext } from '../../App';

interface UserMenuProps {
    settingsActive: boolean;
    setSettingsActive: (active: boolean) => void;
    passwordActive: boolean;
    setPasswordActive: (active: boolean) => void;
}

const UserMenu: React.FC<UserMenuProps> = ({ settingsActive, setSettingsActive, passwordActive, setPasswordActive }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef2 = useRef<HTMLDivElement>(null);


    const { authUserName, authUserEmail } = useContext(appContext);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef2.current && !dropdownRef2.current.contains(event.target as Node)) {
                toggleDropdown();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen((prev) => !prev);
    };

    const handleLogout = () => {
        logOut(() => { });
        setIsOpen(false);
    };

    const handlePasswordClick = () => {
        setPasswordActive(true);
        setIsOpen(false);
    };

    const handleSettingsClick = () => {
        setSettingsActive(true);
        setIsOpen(false);
    };

    return (
        <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">

            <button
                type="button"
                className="flex justify-center items-center text-sm bg-blue-950 rounded-lg focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                id="user-menu-button"
                aria-expanded={isOpen}
                onClick={toggleDropdown}
            >
                <span className="sr-only">Open user menu</span>
                <div className="flex justify-center items-center w-7 h-7 md:w-10 md:h-10  text-lg md:text-2xl text-white">
                    <FontAwesomeIcon icon={faUser} />
                </div>
            </button>


            {isOpen && (
                <div ref={dropdownRef2} className="fixed top-14 right-5 z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow">
                    <ul className="py-2" aria-labelledby="user-menu-button">
                        <li>
                            <span className="block px-4 font-semibold text-sm text-[#03436f] text-center"> {authUserName} </span>
                        </li>
                        <li>
                            <span className="block px-4 text-xs text-[#03436f] text-center"> {authUserEmail} </span>
                        </li>
                        <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"/>
                        <li>
                            <span
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                onClick={handlePasswordClick}
                            >
                                Password
                            </span>
                        </li>
                        <li>
                            <span
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                onClick={handleSettingsClick}
                            >
                                Payout Settings
                            </span>
                        </li>
                        <li>
                            <span
                                className="block px-4 py-2 text-sm text-red-700 hover:bg-gray-100 cursor-pointer"
                                onClick={handleLogout}
                            >
                                Sign out
                            </span>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default UserMenu;
